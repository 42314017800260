import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash.get';
import { FastField, Field, useFormikContext, useField } from 'formik';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Card, CardContent, IconButton, Collapse, Paper } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';

import { typeOptions, enumTypes } from '../../../../utils/constants/formBuilder';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import FormSwitch from '../../../components/FormSwitch';
import SingleFileUploaderInput from '../../../components/SingleFileUploaderInput';
import AddRow from '../AddRow';
import SortableWrap from '../SortableWrap';
import OptionList from '../OptionList';
import { addendumFile } from '../../../../actionTypes';
import useStyles from './styles';
import { fileAddendumUploadUrl } from '../../../../utils/constants';

const handleAddQuestion = (setFieldValue, values, parentId, parentAnswerCondition = null) => {
  const generateId = uuidv4();
  setFieldValue(
    'fields',
    {
      ...values,
      [generateId]: {
        id: generateId,
        generated: true,
        parentId,
        showOnCondition: !!parentAnswerCondition,
        order: Object.keys(values).filter(key => values[key].parentId === parentId).length,
        text: '',
        type: enumTypes.Paragraph,
        isRequired: false,
        hasChild: false,
        isActive: true,
        children: {},
        options: [],
        parentAnswerCondition,
      },
    },
    false,
  );
};

const getChildrenByRule = (obj, name, answer = null) =>
  Object.keys(obj).filter(item => obj[item].parentId === name && obj[item].parentAnswerCondition === answer);

const FormSelectController = props => {
  const handleClearFields = async e => {
    const name = props.field.name.slice(0, -5);
    const valObj = get(props.form.values, name, {});
    
    if (valObj.type !== e.target.value) {
      await props.form.setFieldValue(
        name,
        {
          ...valObj,
          isRequired: e.target.value === enumTypes.Title ? false : valObj.isRequired,
          hasChild: false,
          children: [],
          options: [],
          type: e.target.value,
        },
        false,
      );
    }

    if (e.target.value === enumTypes.File || valObj.type === enumTypes.File) {
      const isFile = e.target.value === enumTypes.File;

      await props.form.setFieldValue(`${name}.text`, isFile ? 'Please upload the file' : '', isFile);
    }
  };

  return <FormSelect {...props} handleChange={handleClearFields} />;
};

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const SingleQuestion = ({ name, handleRemove, companyId }) => {
  const { setFieldValue, values } = useFormikContext();
  const [, fileIdFieldMeta] = useField(`${name}.fileId`);
  const objValues = get(values, name, {});
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDownload = () => {
    const isTempFile = fileIdFieldMeta.value !== fileIdFieldMeta.initialValue;
    const currentValues = get(values, name, {});

    dispatch({
      type: addendumFile.downloadByFormBuilderFieldId.start,
      payload: {
        companyId,
        fileId: isTempFile ? currentValues.fileId : objValues.id,
        fileName: currentValues.fileName,
        tempFile: isTempFile,
      },
    });
  };

  return (
    <Grid item xs={12}>
      <Card elevation={2}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item>
                  <DragHandle />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Field
                    component={FormSelectController}
                    name={`${name}.type`}
                    options={typeOptions}
                    label="Question type"
                  />
                </Grid>
                <Grid item xs={5}>
                  <FastField component={FormInput} name={`${name}.text`} label="Question title" />
                </Grid>
                <Grid item xs={2}>
                  {![enumTypes.Title, enumTypes.Link].includes(objValues.type) && (
                    <Grid container spacing={1} alignItems="center" justify="center">
                      <Grid item xs={6}>
                        <Typography variant="body1">Is required</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FastField component={FormSwitch} name={`${name}.isRequired`} />
                      </Grid>
                    </Grid>
                  )}
                  {objValues.type === enumTypes.Link && (
                    <Grid container spacing={2} alignItems="center" justify="center">
                      <Grid item>
                        <FastField
                          name={`${name}.fileId`}
                          fileNameFieldName={`${name}.fileName`}
                          component={SingleFileUploaderInput}
                          companyId={companyId}
                          handleDownload={handleDownload}
                          uploadUrl={fileAddendumUploadUrl}
                          editable={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item>
                      <Typography variant="body1">Show</Typography>
                    </Grid>
                    <Grid item>
                      <FastField component={FormSwitch} name={`${name}.isActive`} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid container alignItems="center" justify="flex-end">
                    <IconButton color="secondary" onClick={handleRemove} size="small">
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Collapse
              in={objValues.type === enumTypes.YesNo}
              classes={{
                container: classes.collapseContainer,
                wrapperInner: classes.wrapperInner,
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center" justify="flex-start">
                  <Grid item>
                    <Typography variant="body1">Add children</Typography>
                  </Grid>
                  <Grid item>
                    <FastField component={FormSwitch} name={`${name}.hasChild`} />
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={objValues.type === enumTypes.Dropdown}
              classes={{
                container: classes.collapseContainer,
                wrapperInner: classes.wrapperInner,
              }}
            >
              <Grid item xs={12}>
                <OptionList name={`${name}.options`} />
              </Grid>
            </Collapse>

            <Collapse
              in={objValues.hasChild && objValues.type === enumTypes.YesNo}
              classes={{
                container: classes.collapseContainer,
                wrapperInner: classes.wrapperInner,
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Show on condition
                </Typography>
                <Paper variant="outlined" className={classes.paperContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5"> If answer Yes </Typography>
                    </Grid>
                    <SortableWrap parent={name} childrenKeys={getChildrenByRule(values.fields, objValues.id, 'Yes')} />
                    <AddRow handleAdd={() => handleAddQuestion(setFieldValue, values.fields, objValues.id, 'Yes')} />
                  </Grid>
                </Paper>
                <Paper variant="outlined" className={classes.paperContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5"> If answer No </Typography>
                    </Grid>

                    <SortableWrap childrenKeys={getChildrenByRule(values.fields, objValues.id, 'No')} parent={name} />
                    <AddRow handleAdd={() => handleAddQuestion(setFieldValue, values.fields, objValues.id, 'No')} />
                  </Grid>
                </Paper>
              </Grid>
            </Collapse>

            <Collapse
              in={objValues.type === enumTypes.Title}
              classes={{
                container: classes.collapseContainer,
                wrapperInner: classes.wrapperInner,
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <SortableWrap childrenKeys={getChildrenByRule(values.fields, objValues.id, null)} parent={name} />
                  <Grid item xs={12}>
                    <AddRow handleAdd={() => handleAddQuestion(setFieldValue, values.fields, objValues.id)} />
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SortableElement(SingleQuestion);
